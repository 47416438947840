import { Component, EventEmitter, HostListener, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { TimeGap } from '../time-gap/time-gap';
import { TimeGapHumanizePipe } from '../time-gap/time-gap-humanize.pipe';
import * as moment from 'moment';

@Component({
  selector: 'mc-countdown-timer',
  templateUrl: './countdown-timer.component.html',
  styleUrls: ['./countdown-timer.component.scss']
})
export class CountdownTimerComponent implements OnDestroy, OnChanges {

  @Input() deadline: number;
  @Input() openMessage = 'Kończą się ';
  @Input() finishedMessage = 'Zakończone ';
  @Input() closedMessage = 'Zamknięte ';
  @Output() private closing = new EventEmitter();
  private interval: any;

  timeGap: TimeGap;
  timeLeft: number | null;
  message: string;

  constructor(private humanize: TimeGapHumanizePipe) {
    this.timeGap = new TimeGap({
      autoStart: false,
      onUpdate: (diff: any) => {
        this.timeLeft = diff;
        if (this.deadline === null || !this.timeGap) {
          this.message = this.closedMessage;
        } else if (this.timeGap.isExpired()) {
          // this.message = this.finishedMessage + this.humanize.transform(this.timeLeft);
          this.message = this.finishedMessage + moment().to(this.deadline);
        } else {
          // this.message = this.openMessage + this.humanize.transform(this.timeLeft);
          this.message = this.openMessage + moment().to(this.deadline);
        }
      },
      onExpire: this.closing.emit('closed')
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.deadline) {
      if (this.deadline === null) {
        this.timeGap.stop();
      } else {
        this.timeGap
          .set(this.deadline)
          .start();
      }
    }
  }

  @HostListener('click')
  onClick(): void {
    if (this.timeGap.isCounting()) {
      this.stop();
    } else {
      this.start();
    }
  }

  getFormatted(): string {
    return this.timeGap.humanized();
  }

  start(): void {
    this.timeGap.start();
  }

  stop(): void {
    this.timeGap.stop();
  }

  ngOnDestroy(): void {
    this.timeGap.stop();
  }
}
