// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  pouchDbUrl: 'https://db.mc.zduniak.net/',
  GOOGLE_API_KEY: 'AIzaSyBqDOLRero8bDzj1VMOzkLqiY4-laS9Kbg',
  isAdmin: window.location.host.indexOf('mc-webapp') === 0
};
