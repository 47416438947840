import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Standing } from '../standing';
import { Result } from '../results/result/result';
import { DriversService } from '../../gepard/drivers/drivers.service';
import { StandingsConfig } from './standings-config';
import { ResultsConfig } from '../results/results-config/results-config';
import { ResultsFilterPipe } from '../results/results-config/results-filter.pipe';
import { LaptimeRangeFilterPipe } from '../results/result/laptime-range-filter.pipe';
import { SeasonPointsPipe } from '../../seasons/season-points.pipe';
import { StandingsFilterPipe } from './standings-filter.pipe';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'mc-standings',
  templateUrl: './standings.component.html',
  styleUrls: ['./standings.component.scss']
})
export class StandingsComponent implements OnInit, OnDestroy, OnChanges {

  @Input() standings: Standing[] = [];

  @Input() standingsConfig: StandingsConfig = {};

  @Input() resultsConfig: ResultsConfig = {
    minimumLapSeconds: 60,
    maximumLapSeconds: 360,
    rankingLapsNumber: 3
  };

  standings$: Observable<Standing[]> = new Subject();

  get drivers() {
    return this.driversService.drivers;
  }

  @Output() refresh = new EventEmitter<Result>();
  @Output() save = new EventEmitter<Result>();
  @Output() remove = new EventEmitter<Result>();
  @Output() accept = new EventEmitter<Result>();
  @Output() reject = new EventEmitter<Result>();
  @Output() updated = new EventEmitter<Standing[]>();

  constructor(
    private driversService: DriversService,
    private resultsFilter: ResultsFilterPipe,
    private laptimeRangeFilterPipe: LaptimeRangeFilterPipe,
    private seasonPointsFilterPipe: SeasonPointsPipe,
    private standingsFilterPipe: StandingsFilterPipe
  ) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.standings) {
      console.log('standings changed');
      this.recalculate();
    } else if (changes.standingsConfig) {
      console.log('standingsConfig changed');
      // this.standings = this.standingsFilterPipe.transform(this.standings, this.standingsConfig);
      this.recalculate();
    } else if (changes.resultsConfig) {
      console.log('resultsConfig changed');
      this.recalculate();
    }
  }

  recalculate() {
    this.standings
      .forEach(standing => {
        standing.rankingResults = this.recalculateResults(standing.results);
        standing.rankingResultsAverage = this.average(standing.rankingResults.map(result => result.microtime));
      });
    this.recalculateRanking();
    // this.standings.forEach(s => s.results.forEach(r => this.resultsMetaService.get(r)));
    // this.resultsMetaService.recalculateDuplicates();
  }

  recalculateResults(results: Result[]): Result[] {
    return this.resultsFilter.transform(results, this.resultsConfig)
      .sort((a, b) => a.microtime - b.microtime)
      .slice(0, this.resultsConfig.rankingLapsNumber);
  }

  recalculateRanking() {
    this.standings.sort((a, b) => a.rankingResultsAverage === 0 ? 1 : a.rankingResultsAverage - b.rankingResultsAverage);
    this.standings.forEach((standing, i) => {
      standing.position = i + 1;
      standing.seasonPoints = this.seasonPointsFilterPipe.transform(standing.position);
    });
  }

  average(results, n = 3) {
    let i;
    let sum = 0;
    for (i = 0; i < results.length && (n <= 0 || i < n); i++) {
      sum += results[i];
    }
    return i ? (sum / i) : null;
  }

  standingToAverage(index: number, standing: Standing): number {
    return standing.rankingResultsAverage;
  }

  isInRange(microtime: number) {
    return this.laptimeRangeFilterPipe.transform(microtime, this.resultsConfig);
  }

  isActionUsed(action: string) {
    return this[action].observers.length > 0;
  }

  ngOnDestroy() {
    console.log('destroy standings');
  }
}
