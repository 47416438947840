<table class="table table-hover">
	<thead>
		<tr>
			<th scope="col">
				<a (click)="setSortBy('id')" class="sortable" title="Sortuj po ID">
					#
					<i class="fa"
						[class.fa-angle-down]="sortBy.column === 'id' && sortBy.direction === 'asc'"
						[class.fa-angle-up]="sortBy.column === 'id' && sortBy.direction === 'desc'"
					></i>
				</a>
			</th>
			<th scope="col" (click)="setSortBy('name')" class="sortable" title="Sortuj po nazwie">
				Nazwa
				<i class="fa"
					[class.fa-angle-down]="sortBy.column === 'name' && sortBy.direction === 'asc'"
					[class.fa-angle-up]="sortBy.column === 'name' && sortBy.direction === 'desc'"
				></i>
			</th>
			<th scope="col" (click)="setSortBy('subscriptionsEndAt')" class="sortable" title="Sortuj po terminie zakończenia zapisów">
				Zapisy
				<i class="fa"
					[class.fa-angle-down]="sortBy.column === 'subscriptionsEndAt' && sortBy.direction === 'asc'"
					[class.fa-angle-up]="sortBy.column === 'subscriptionsEndAt' && sortBy.direction === 'desc'"
				></i>
			</th>
			<th scope="col">
				<button class="btn btn-primary btn-sm fa fa-refresh" (click)="readAll()" title="Odśwież"></button>
				<button class="btn btn-primary btn-sm fa fa-refresh" (click)="readAll()" title="Reset"></button>
			</th>
		</tr>
	</thead>
	<tbody>
		<tr *ngFor="let model of collection">
			<th scope="row">{{model['id']}}</th>
			<td>{{model['name']}}</td>
			<td>
				<mc-countdown-timer [deadline]="model['subscriptionsEndAt']"></mc-countdown-timer>
			</td>
			<td>
				<button class="btn btn-primary btn-sm fa fa-paper-plane-o"
					popover="Otwórz zapisy"
					placement="left"
					*ngIf="model['subscriptionsEndAt'] === null"
					[routerLink]="['/seasons', model['id'], 'opening']"
				></button>
				<button class="btn btn-secondary btn-sm fa fa-handshake-o" popover="Zapisz się na sezon" placement="left"></button>
				<button class="btn btn-secondary btn-sm fa fa-pencil" (click)="update({name: 'test'})" popover="Edytuj"></button>
				<button class="btn btn-secondary btn-sm fa fa-trash" (click)="delete(model['id'])" popover="Usuń" placement="right"></button>
			</td>
		</tr>
	</tbody>
</table>
